<template>
  <div>
    <en-table-layout
      :tableData="tableData.data"
      :loading="loading"
      :tips="true"
      v-if="step==='couponList'"
    >
      <!-- 搜索条件 -->
      <div slot="tips" class="TitleBox">
        <div class="conditions">
          <div>优惠券类型：</div>
          <el-select
            class="choose-machine"
            v-model="params.coupon_type"
            placeholder="请选择优惠券类型"
            clearable
            size="mini"
          >
            <el-option
              v-for="item in couponTypeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="conditions">
          <div>是否定向：</div>
          <el-select
            class="choose-machine"
            v-model="params.fixed_sign"
            placeholder="请选择是否定向"
            clearable
            size="mini"
          >
            <el-option
              v-for="item in directionalList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="conditions">
          <div>状态选择：</div>
          <el-select
            class="choose-machine"
            v-model="params.coupon_status"
            placeholder="请选择状态"
            clearable
            size="mini"
          >
            <el-option v-for="item in stateList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </div>
        <div class="conditions">
          <el-button
            @click="GET_sellerPromotionCoupons"
            type="primary"
            class="btn-default"
            size="mini"
          >筛选
          </el-button>
        </div>
        <div class="conditions">
          <el-button @click="openDialog('add')" type="primary" class="btn-default" size="mini">新增</el-button>
        </div>
        <div class="conditions">
          <el-button
            @click="openDialog('directional')"
            type="primary"
            class="btn-default"
            size="mini"
          >定向发放
          </el-button>
        </div>
      </div>
      <template slot="table-columns">
        <el-table-column label=" " width="55" fixed="left">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.checked" @change="couponsTableChoose(scope.row.index)"></el-checkbox>
          </template>
        </el-table-column>
        <!--优惠券名称-->
        <el-table-column label="优惠券名称" fixed="left" width="200">
          <template slot-scope="scope">
            <div style="width:180px;text-align:center;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;"
                 :title="scope.row.title">{{ scope.row.title }}
            </div>
          </template>
        </el-table-column>
        <!--面额-->
        <el-table-column label="面额">
          <template slot-scope="scope">
            <div>
              {{
                scope.row.coupon_type === 1 ? scope.row.coupon_price + "元，客户消费满" + scope.row.coupon_threshold_price + "元可用" : ""
              }}
            </div>
          </template>
        </el-table-column>
        <!--减免使用条件-->
        <el-table-column label="减免使用条件">
          <template slot-scope="scope">
            <div>
              {{
                scope.row.coupon_type === 2 ? "每笔订单客户每消费满" + scope.row.every_sub_price + "元，减" + scope.row.full_sub_price + ",总减金额最高不超过" + scope.row.all_sub_price : ""
              }}
            </div>
          </template>
        </el-table-column>
        <!--折扣率-->
        <el-table-column label="折扣率">
          <template slot-scope="scope">
            <div>
              {{
                scope.row.coupon_type === 3 ? "客户消费满" + scope.row.discount_price + "享受" + scope.row.discount_num + "%优惠" : ""
              }}
            </div>
          </template>
        </el-table-column>
        <!--有效期-->
        <el-table-column label="有效期" width="164">
          <template slot-scope="scope">
            <div>
              {{ MixinUnixToDate("", "", scope.row.start_time) + "至" + MixinUnixToDate("", "", scope.row.end_time) }}
            </div>
          </template>
        </el-table-column>
        <!--已发行量-->
        <el-table-column label="已发行量" prop="create_num"/>
        <!--已使用量-->
        <el-table-column label="已使用量" prop="used_num"/>
        <!--是否定向-->
        <el-table-column label="是否定向">
          <template slot-scope="scope">
            <div>{{ scope.row.fixed_sign === 1 ? "是" : "否" }}</div>
          </template>
        </el-table-column>
        <!-- 定向客户类型 -->
        <el-table-column label="定向客户类型">
          <template slot-scope="scope">
            <div v-if="scope.row.fixed_sign === 1">
              {{ scope.row.member_range_type === 1 ? '关注店铺客户' : scope.row.member_range_type === 2 ? '历史下单客户' : '收藏店铺活动客户'}}
            </div>
            <div v-else>
              <span>-</span>
            </div>
          </template>
        </el-table-column>
        <!--优惠券类型-->
        <el-table-column label="优惠券类型">
          <template slot-scope="scope">
            <div>
              {{
                scope.row.coupon_type === 1 ? "面额型" : scope.row.coupon_type === 2 ? "满减型" : scope.row.coupon_type === 3 ? "折扣型" : ""
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="状态" fixed="right">
          <template slot-scope="scope">
            <div>
              {{
                scope.row.status === 1 ? "进行中" : scope.row.status === 2 ? "未开始" : scope.row.status === 3 ? "已结束" : "已停用"
              }}
            </div>
          </template>
        </el-table-column>
        <!--操作-->
        <el-table-column label="操作" width="130" fixed="right">
          <template slot-scope="scope">
            <div class="buttonBox">
              <el-button
                @click="openDialog('edit',scope.row)"
                type="text"
                size="mini"
                v-if="(scope.row.status===2)||scope.row.status===4"
                style="margin-bottom:5px;"
              >编辑
              </el-button>
              <el-button
                @click="openDialog('toCouponDetailsList',scope.row)"
                type="text"
                size="mini"
                style="margin-bottom:5px;"
              >查看明细
              </el-button>
              <el-button
                @click="openDialog('stop',scope.row)"
                type="text"
                size="mini"
                v-if="scope.row.status===1||scope.row.status===2"
                style="margin-bottom:5px;color:#fe5558;"
              >停用
              </el-button>
              <el-button
                @click="openDialog('on',scope.row)"
                type="text"
                size="mini"
                v-if="scope.row.status===4"
                style="margin-bottom:5px;"
              >启用
              </el-button>
              <el-button
                @click="openDialog('del',scope.row)"
                type="text"
                size="mini"
                v-if="scope.row.status===2||scope.row.status===3"
                style="margin-bottom:5px;color:#fe5558;"
              >删除
              </el-button>
            </div>
          </template>
        </el-table-column>
      </template>
      <el-pagination
        v-if="tableData.data"
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="tableData.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.data_total"
      ></el-pagination>
    </en-table-layout>

    <el-dialog :title="dialogTitle" :visible.sync="centerDialogVisible" width="43%" center>
      <div
        class="dialogContent"
        v-if="this.dialogTitle === '停用优惠券'||this.dialogTitle === '删除优惠券'"
      >{{ dialogContent }}
      </div>
      <div class="addAndEdit" v-if="this.dialogTitle === '添加优惠券'||this.dialogTitle === '修改优惠券'">
        <el-form
          v-if="centerDialogVisible"
          :model="addAndEditParams"
          :rules="addAndEditParamsRules"
          ref="addAndEdit"
          label-width="120px"
          style="padding-bottom:0;"
        >
          <el-form-item label="优惠券名称：" prop="title">
            <el-input v-model="addAndEditParams.title" placeholder="请输入优惠券名称" size="medium"></el-input>
          </el-form-item>
          <el-form-item label="优惠券类型：" prop="coupon_type">
            <el-select
              class="choose-machine"
              v-model="addAndEditParams.coupon_type"
              placeholder="请选择优惠券类型"
              clearable
              @change="couponTypeChange"
            >
              <el-option
                v-for="item in couponTypeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="优惠券面额：" prop="price" v-if="addAndEditParams.coupon_type===1">
            <div style="display:flex;align-items:center;">
              <el-input v-model="addAndEditParams.coupon_price" size="medium"></el-input>
              <div style="width:320px;">元，客户消费满</div>
              <el-input v-model="addAndEditParams.coupon_threshold_price" size="medium"></el-input>
              <div style="width:160px;">元可用</div>
            </div>
          </el-form-item>
          <el-form-item label="优惠券条件：" prop="condition" v-if="addAndEditParams.coupon_type===2">
            <div style="display:flex;align-items:center;">
              <div style="width:510px;">每笔订单客户每消费满</div>
              <el-input v-model="addAndEditParams.every_sub_price" size="medium"></el-input>
              <div style="width:160px;">元，减</div>
              <el-input v-model="addAndEditParams.full_sub_price" size="medium"></el-input>元
            </div>
            <div style="display:flex;align-items:center;">
              <div style="width:190px;">总减金额最高不超过</div>
              <el-input v-model="addAndEditParams.all_sub_price" size="medium"></el-input>元
            </div>
          </el-form-item>
          <el-form-item label="折扣优惠：" prop="discount" v-if="addAndEditParams.coupon_type===3">
            <div style="display:flex;align-items:center;">
              <div style="width:200px;">客户消费满</div>
              <el-input v-model="addAndEditParams.discount_price" autocomplete="on" size="medium"></el-input>元
              <div style="width:100px;">享受</div>
              <el-input v-model="addAndEditParams.discount_num" size="medium" style="width:240px;"></el-input>
              <div style="width:180px;">(%)优惠</div>
            </div>
            <div>
              <p style="font-size:14px; color: #99999; height: 16px; margin-bottom: 10px;">
                示例:享受85%优惠，即八五折 享受90%优惠，即九折
              </p>
              <!-- <span style="font-size:12px; color: #99999;"></span> -->
            </div>
          </el-form-item>
          <el-form-item label="使用期限：" prop="start_time">
            <el-date-picker
              v-model="times"
              style="width:220px;"
              type="daterange"
              align="center"
              :editable="false"
              size="small"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="timestamp"
              :picker-options="{ disabledDate(time) { return time.getTime() + 86400000 < Date.now() }}"
              @change="dateChange"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="是否定向：" prop="fixed_sign">
            <div style="width:250px;">
              <el-radio
                v-model="addAndEditParams.fixed_sign"
                :label="1"
                @change="directionalChange"
              >是(指定用户)
              </el-radio>
              <el-radio
                v-model="addAndEditParams.fixed_sign"
                :label="0"
                @change="directionalChange"
              >否(全部用户)
              </el-radio>
            </div>
          </el-form-item>
          <el-form-item
            label="用户类型："
            prop="member_range_type"
            v-if="addAndEditParams.fixed_sign===1"
          >
            <el-select
              class="choose-machine"
              v-model="addAndEditParams.member_range_type"
              placeholder="请选择用户类型"
              clearable
            >
              <el-option
                v-for="item in userTypeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="directional" v-if="this.dialogTitle === '定向发放优惠券'">
        <el-form
          :model="directionalUserParams"
          :rules="directionalUserParamsRules"
          ref="directional"
          label-width="120px"
          style="padding-bottom:0;"
        >
          <el-form-item label="发放用户：" prop="directionalUser">
            <el-input
              v-model="directionalUserParams.directionalUser"
              placeholder="请选择要发放的用户手机号，多个手机号用英文逗号隔开"
              size="medium"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label=" ">
            <div class="searchBox">
              <el-select
                class="choose-machine"
                v-model="directionalParams.sign"
                placeholder="请选择用户类型"
                clearable
              >
                <el-option
                  v-for="item in directionalTypeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
              <el-button
                @click="GET_sellerPromotionCouponsMember()"
                type="primary"
                class="btn-default"
                size="medium"
                style="margin-left:20px;"
              >筛选
              </el-button>
            </div>
            <en-table-layout
              :tableData="directionalTableData.data"
              :loading="loading"
              :tips="true"
              class="directionalTable"
            >
              <template slot="table-columns">
                <el-table-column label=" " width="55">
                  <template slot-scope="scope">
                    <el-checkbox v-model="scope.row.checked" @change="tableChoose(scope.row)"></el-checkbox>
                  </template>
                </el-table-column>
                <!--用户名称-->
                <el-table-column label="用户名称" prop="uname"/>
                <!--用户手机号-->
                <el-table-column label="用户手机号" prop="mobile"/>
              </template>
              <el-pagination
                v-if="directionalTableData.data"
                slot="pagination"
                @current-change="directionalCurrentChange"
                :current-page="directionalTableData.page_no"
                :page-size="directionalTableData.page_size"
                layout="total, prev, pager, next, jumper"
                :total="directionalTableData.data_total"
                class="directionalPagination"
              ></el-pagination>
            </en-table-layout>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="ok">确 定</el-button>
      </span>
    </el-dialog>

    <en-table-layout
      :tableData="detailsTableData.data"
      :loading="loading"
      :tips="true"
      class="detailsTable"
      v-if="step==='couponDetailsList'"
    >
      <!-- 表格上部按钮 -->
      <div slot="toolbar">
        <div>
          <el-button class="primary" size="mini" @click="() => step = 'couponList'">返回</el-button>
        </div>
        <div style="display:flex;">
          <el-input
            v-model="detailsParams.value"
            placeholder="请输入用户名/手机号"
            size="mini"
            style="margin-right:20px;flex:1;"
          ></el-input>
          <el-button
            @click="GET_sellerPromotionCouponsDetails"
            type="primary"
            class="btn-default"
            size="mini"
          >筛选
          </el-button>
          <el-button class="primary" size="mini" @click="downLoad">导出明细</el-button>
        </div>
      </div>
      <template slot="table-columns">
        <!--客户名称-->
        <el-table-column label="客户名称">
          <template slot-scope="scope">
            {{ unescape(scope.row.uname) }}
          </template>
        </el-table-column>
        <!--客户手机号-->
        <el-table-column label="客户手机号">
          <template slot-scope="scope">
            {{ scope.row.mobile }}
          </template>
        </el-table-column>
        <!--优惠券发放时间-->
        <el-table-column label="优惠券发放时间" width="160">
          <template slot-scope="scope">
            {{ MixinUnixToDate("", "", scope.row.create_date )}}
          </template>
        </el-table-column>
        <!--是否使用优惠券-->
        <el-table-column label="是否使用优惠券">
          <template slot-scope="scope">
            <div>{{ scope.row.used_status === 1 ? '未使用' : '已使用' }}</div>
          </template>
        </el-table-column>
        <!--活动名称-->
        <el-table-column label="活动名称">
          <template slot-scope="scope">
            {{ scope.row.goods_name}}
          </template>
        </el-table-column>
        <!--订单编号-->
        <el-table-column label="订单编号">
          <template slot-scope="scope">
            {{ scope.row.order_sn}}
          </template>
        </el-table-column>
        <!--活动价格-->
        <el-table-column label="活动价格">
          <template slot-scope="scope">
            {{ scope.row.order_price}}
          </template>
        </el-table-column>
        <!--操作-->
        <el-table-column label="操作" width="130">
          <template slot-scope="scope">
            <div class="buttonBox">
              <el-button
              v-if="scope.row.used_status === 2"
                @click="openDialog('details',scope.row)"
                type="text"
                size="mini"
                style="margin-bottom:5px;"
              >订单详情
              </el-button>
            </div>
          </template>
        </el-table-column>
      </template>
      <el-pagination
        v-if="detailsTableData.data"
        slot="pagination"
        @size-change="detailsSizeChange"
        @current-change="detailsCurrentChange"
        :current-page="detailsTableData.page_no"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="detailsTableData.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="detailsTableData.data_total"
      ></el-pagination>
    </en-table-layout>
  </div>
</template>

<script>
import * as Export2Excel from "@/utils/exportXlsx";
import * as API_couponList from "@/api/couponList";

export default {
  name: "activityList",
  data() {
    const price = (rule, value, callback) => {
      if (
        this.addAndEditParams.coupon_price === "" ||
        this.addAndEditParams.coupon_threshold_price === ""
      ) {
        callback(new Error("请填写全优惠券面额"));
      } else if (this.addAndEditParams.coupon_price <= 0 || this.addAndEditParams.coupon_threshold_price <= 0) {
        callback(new Error("优惠面额或消费金额不能为0"));
      } else if (parseInt(this.addAndEditParams.coupon_price) >= parseInt(this.addAndEditParams.coupon_threshold_price)) {
        callback(new Error("优惠金额必须小于消费金额"))
      } else if (
        this.addAndEditParams.coupon_price % 1 !== 0 ||
        this.addAndEditParams.coupon_threshold_price % 1 !== 0
      ) {
        callback(new Error("优惠金额或满减金额只能为整数"))
      } else {
        callback();
      }
    };
    const condition = (rule, value, callback) => {
      if (
        this.addAndEditParams.every_sub_price === "" ||
        this.addAndEditParams.full_sub_price === "" ||
        this.addAndEditParams.all_sub_price === ""
      ) {
        callback(new Error("请完善满减优惠券表单"));
      } else if (
        this.addAndEditParams.every_sub_price <= 0 ||
        this.addAndEditParams.full_sub_price <= 0 ||
        this.addAndEditParams.all_sub_price <= 0
      ) {
        callback(new Error('优惠金额不能为0或小于0'))
      } else if (
        parseInt(this.addAndEditParams.every_sub_price) <= parseInt(this.addAndEditParams.full_sub_price)
      ) {
        callback(new Error('优惠金额必须小于消费金额'))
      } else if (parseInt(this.addAndEditParams.full_sub_price) > parseInt(this.addAndEditParams.all_sub_price)) {
        callback(new Error('优惠金额必须小于总优惠金额'))
      } else if (
        this.addAndEditParams.every_sub_price % 1 !== 0 ||
        this.addAndEditParams.full_sub_price % 1 !== 0 ||
        this.addAndEditParams.all_sub_price % 1 !== 0
      ) {
        callback(new Error('优惠金额或满减金额只能为整数'))
      } else {
        callback();
      }
    };
    const discount = (rule, value, callback) => {
      if (
        this.addAndEditParams.discount_price === "" ||
        this.addAndEditParams.discount_num === ""
      ) {
        callback(new Error("请填写全折扣优惠"));
      } else if (this.addAndEditParams.discount_price < 0) {
        callback(new Error('满减金额必须大于0'))
      } else if (this.addAndEditParams.discount_num < 10 || this.addAndEditParams.discount_num > 95) {
        callback(new Error('折扣数必须大于10小于95'))
      } else if (this.addAndEditParams.discount_num >= 100) {
        callback(new Error('折扣数必须小于100'))
      } else if (
        this.addAndEditParams.discount_price % 1 !== 0 ||
        this.addAndEditParams.discount_num % 1 !== 0
      ) {
        callback(new Error('满减金额或折扣数只能为整数'))
      } else {
        callback();
      }
    };
    return {
      //  列表loading状态
      loading: false,
      //  列表参数
      params: {
        page_no: 1,
        page_size: 10,
        coupon_type: "",
        fixed_sign: "",
        coupon_status: "",
      },

      //  点击添加和编辑参数
      addAndEditParams: {
        all_sub_price: '',
        every_sub_price: '',
        full_sub_price: '',
        coupon_threshold_price: '',
        coupon_price: '',
        discount_num: '',
        discount_price: 0,
        start_time: '',
        end_time: '',
        fixed_sign: '',
      },

      //  点击添加和编辑参数校验
      addAndEditParamsRules: {
        title: [
          {
            required: true,
            message: "请填写优惠券名称",
            trigger: "blur",
          },
        ],
        coupon_type: [
          {
            required: true,
            message: "请选择优惠券类型",
            trigger: "type",
          },
        ],
        price: [{
          required: true,
          validator: price,
          trigger: "blur"
        }],
        condition: [{
          required: true,
          validator: condition,
          trigger: "blur"
        }],
        discount: [{
          required: true,
          validator: discount,
          trigger: "blur"
        }],
        start_time: [
          {
            required: true,
            message: "请选择使用期限",
            trigger: "blur",
          },
        ],
        fixed_sign: [
          {
            required: true,
            message: "请选择是否定向",
            trigger: "blur",
          },
        ],
        member_range_type: [
          {
            required: true,
            message: "请选择用户类型",
            trigger: "blur",
          },
        ],
      },

      //  列表数据
      tableData: {},

      // 弹框显示内容
      dialogTitle: "",
      dialogContent: "",
      centerDialogVisible: false,

      // 优惠券类型
      couponTypeList: [
        {
          id: 1,
          name: "面额",
        },
        {
          id: 2,
          name: "满减",
        },
        {
          id: 3,
          name: "折扣",
        },
      ],

      // 是否定向
      directionalList: [
        {
          id: "0",
          name: "否",
        },
        {
          id: "1",
          name: "是",
        },
      ],

      // 优惠券状态
      stateList: [
        {
          id: "",
          name: "全部",
        },
        {
          id: "2",
          name: "未开始",
        },
        {
          id: "1",
          name: "进行中",
        },
        {
          id: "3",
          name: "已结束",
        },
        {
          id: "4",
          name: "已停用",
        },
      ],

      // 优惠券用户类型
      userTypeList: [
        {
          id: 1,
          name: "关注店铺可领取",
        },
        {
          id: 2,
          name: "下单用户可领取",
        },
        {
          id: 3,
          name: "收藏店铺活动可领取",
        },
      ],

      // 使用期限时间数组
      times: [],

      // 需要发放的用户
      directionalUserParams: {
        ids: "",
        couponId: "",
        directionalUser: "",
      },

      // 需要发放的用户校验
      directionalUserParamsRules: {
        directionalUser: [
          {
            required: true,
            message: "请选择需要发放的用户",
            trigger: "type",
          },
        ],
      },

      // 查询用户
      directionalParams: {
        page_no: 1,
        page_size: 10,
        sign: "",
      },

      // 定向选择用户类型
      directionalTypeList: [
        {
          id: 1,
          name: "关注本店铺的用户",
        },
        {
          id: 2,
          name: "下过单的用户",
        },
        {
          id: 3,
          name: "关注店铺活动的用户",
        },
      ],

      // 定向选择用户列表
      directionalTableData: {
        data: [
          {
            member_id: 0,
            checked: false,
            type: "关注店铺可领取",
            uname: "用户名称1",
            mobile: "13111111111",
          },
          {
            member_id: 1,
            checked: false,
            type: "关注店铺可领取",
            uname: "用户名称2",
            mobile: "13111111112",
          },
          {
            member_id: 2,
            checked: false,
            type: "关注店铺可领取",
            uname: "用户名称3",
            mobile: "13111111113",
          },
          {
            member_id: 3,
            checked: false,
            type: "关注店铺可领取",
            uname: "用户名称4",
            mobile: "13111111114",
          },
          {
            member_id: 4,
            checked: false,
            type: "关注店铺可领取",
            uname: "用户名称5",
            mobile: "13111111115",
          },
        ],
      },

      // 选中定向发放集合
      directionalUserList: [],

      // 查询优惠券明细参数
      detailsParams: {
        page_no: 1,
        page_size: 10,
        coupon_id: '',
        value: ""
      },

      // 查询优惠券明细列表
      detailsTableData: {
        data: [
          {
            name: "张三",
            tel: "13111111111",
            time: "2020-01-01 00:00:00",
            used: 1,
            activeName: "活动名称",
            order: "1111111",
            price: "100元",
          },
        ],
      },

      // 当前页面显示
      step: "couponList",

      // 选中的优惠券单行列表行进行操作
      chooseRow: {},

      // 选中的优惠券单行列表行进行定向发放
      chooseRowDirectional: null,
    };
  },
  mounted() {
    // 获取优惠券列表
    this.GET_sellerPromotionCoupons();
  },
  activated() {
    this.GET_sellerPromotionCoupons();
  },
  watch: {
    centerDialogVisible(centerDialogVisible) {
      if (!centerDialogVisible) {
        this.clearDirectionalTableData();
      }
    }
  },
  methods: {
    clearDirectionalTableData() {
      this.directionalTableData.data = this.directionalTableData.data.map(d => {
        d.checked = false;
        return d;
      });
      this.directionalUserParams.directionalUser = '';
      this.$forceUpdate();
    },
    /** 优惠券列表分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.GET_sellerPromotionCoupons();
    },

    /** 优惠券列表分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.GET_sellerPromotionCoupons();
    },

    /** 定向用户列表分页页数发生改变 */
    directionalCurrentChange(page) {
      this.params.page_no = page;
      this.GET_sellerPromotionCouponsMember();
    },

    /** 优惠券明细列表分页大小发生改变 */
    detailsSizeChange(size) {
      this.detailsParams.page_size = size;
      this.GET_sellerPromotionCouponsDetails()
      // this.POST_activeGoodsActivityPage();
    },

    /** 优惠券明细列表分页页数发生改变 */
    detailsCurrentChange(page) {
      this.detailsParams.page_no = page;
      this.GET_sellerPromotionCouponsDetails()
      // this.POST_activeGoodsActivityPage();
    },

    /** 查询优惠券列表 */
    GET_sellerPromotionCoupons() {
      API_couponList.sellerPromotionCoupons(this.params).then((res) => {
        this.tableData = res;
        this.tableData.data.forEach((item, index) => {
          item.checked = false;
          item.index = index;
        });
      });
    },

    /** 添加优惠券 */
    POST_sellerPromotionCouponsAdd() {
      API_couponList.sellerPromotionCouponsAdd(this.addAndEditParams).then(
        (res) => {
          this.$message.success("添加成功！");
          this.addAndEditParams = {};
          this.GET_sellerPromotionCoupons();
        }
      );
    },

    /** 删除优惠券 */
    DELETE_sellerPromotionCouponsDel() {
      API_couponList.sellerPromotionCouponsDel(this.chooseRow.coupon_id).then(
        (res) => {
          this.$message.success("删除成功！");
          this.chooseRow = {};
          this.GET_sellerPromotionCoupons();
        }
      );
    },

    /** 停用启用优惠券 */
    PUT_sellerPromotionCouponsStatus(status) {
      API_couponList.sellerPromotionCouponsStatus(
        this.chooseRow.coupon_id,
        status
      ).then((res) => {
        if (status === 1) {
          this.$message.success("启用成功！");
        } else if (status === 4) {
          this.$message.success("停用成功！");
        }
        this.chooseRow = {};
        this.GET_sellerPromotionCoupons();
      });
    },

    /** 修改优惠券 */
    PUT_sellerPromotionCouponsEdit() {
      API_couponList.sellerPromotionCouponsEdit(this.addAndEditParams).then(
        (res) => {
          this.$message.success("修改成功！");
          this.addAndEditParams = {};
          this.chooseRow = {};
          this.GET_sellerPromotionCoupons();
        }
      );
    },

    /** 查询发放用户 */
    GET_sellerPromotionCouponsMember() {
      API_couponList.sellerPromotionCouponsMember(this.directionalParams).then((res) => {
        this.directionalTableData = res;
        this.directionalTableData.data.forEach((item) => {
          this.$set(item, "checked", false);
        });
      });
    },

    /** 添加优惠券 */
    POST_sellerPromotionCouponsSend() {
      API_couponList.sellerPromotionCouponsSend(
        this.directionalUserParams.ids,
        this.chooseRowDirectional.coupon_id
      ).then((res) => {
        this.clearDirectionalTableData();
        this.$message.success("发放成功！");
        this.chooseRowDirectional = {};
        this.directionalUserParams = {
          ids: "",
          couponId: "",
          directionalUser: "",
        };
        this.directionalUserList = []
        this.GET_sellerPromotionCoupons();
      });
    },

    /** 查询优惠券明细 */
    GET_sellerPromotionCouponsDetails() {
      API_couponList.sellerPromotionCouponsDetails(this.detailsParams).then(
        (res) => {
          this.detailsTableData = res;
          console.log(res);
        }
      );
    },

    /** 打开弹窗或者跳转页面 */
    openDialog(type, row) {
      if (type === 'directional' && !this.chooseRowDirectional) {
        return this.$message.error('请选择一个优惠券');
      }
      if (row) {
        this.chooseRow = row;
      } else {
        this.chooseRow = {};
        this.addAndEditParams = {};
      }
      this.times = [];
      if (type === "add") {
        this.addAndEditParams = {
          all_sub_price: '',
          every_sub_price: '',
          full_sub_price: '',
          coupon_threshold_price: '',
          coupon_price: '',
          discount_num: '',
          discount_price: 0,
          start_time: '',
          end_time: '',
          fixed_sign: '',
        }
        this.dialogTitle = "添加优惠券";
      } else if (type === "edit") {
        this.addAndEditParams = row;
        this.times.push(row.start_time * 1000);
        this.times.push(row.end_time * 1000);
        this.dialogTitle = "修改优惠券";
      } else if (type === "stop") {
        this.dialogTitle = "停用优惠券";
        this.dialogContent = "确认停用优惠券吗？";
      } else if (type === "del") {
        this.dialogTitle = "删除优惠券";
        this.dialogContent = "确认删除优惠券吗？";
      } else if (type === "on") {
        this.PUT_sellerPromotionCouponsStatus(1);
        return false;
      } else if (type === "directional") {
        if (this.chooseRowDirectional.length < 1) {
          this.$message.error("必须选择一个优惠券才能发放！");
          return false;
        } else if (this.chooseRowDirectional.fixed_sign === 0) {
          this.$message.error("请选择定向优惠券发放！");
          return false
        }
        this.dialogTitle = "定向发放优惠券";
        this.GET_sellerPromotionCouponsMember();
      } else if (type === "toCouponDetailsList") {
        this.step = "couponDetailsList";
        this.detailsParams.coupon_id = parseInt(row.coupon_id)
        this.GET_sellerPromotionCouponsDetails();
        return false;
      } else if (type === "details") {
        this.$router.push({
          name: "订单详情",
          params: {
            id: row.order_id,
          },
        });
        return false;
      }
      this.centerDialogVisible = true;
    },

    /** 优惠券类型改变 */
    couponTypeChange() {
      if (this.addAndEditParams.coupon_type === 0) {
        this.addAndEditParamsRules.price[0].required = true;
        this.addAndEditParamsRules.condition[0].required = false;
        this.addAndEditParamsRules.discount[0].required = false;
      } else if (this.addAndEditParams.coupon_type === 1) {
        this.addAndEditParamsRules.price[0].required = false;
        this.addAndEditParamsRules.condition[0].required = true;
        this.addAndEditParamsRules.discount[0].required = false;
      } else if (this.addAndEditParams.coupon_type === 2) {
        this.addAndEditParamsRules.price[0].required = false;
        this.addAndEditParamsRules.condition[0].required = false;
        this.addAndEditParamsRules.discount[0].required = true;
      } else {
        this.addAndEditParamsRules.price[0].required = false;
        this.addAndEditParamsRules.condition[0].required = false;
        this.addAndEditParamsRules.discount[0].required = false;
      }
    },

    /** 是否定向改变 */
    directionalChange() {
      if (this.addAndEditParams.fixed_sign === 0) {
        this.addAndEditParamsRules.member_range_type[0].required = false;
      } else {
        this.addAndEditParamsRules.member_range_type[0].required = true;
      }
    },

    dateChange() {
      if (this.times.length > 0) {
        this.addAndEditParams.start_time = this.times[0] / 1000;
        this.addAndEditParams.end_time = this.times[1] / 1000;
      } else {
        this.addAndEditParams.start_time = '';
        this.addAndEditParams.end_time = '';
      }
      console.log(this.addAndEditParams);
    },

    /** 弹窗点击确定 */
    ok() {
      if (
        this.dialogTitle === "添加优惠券" ||
        this.dialogTitle === "修改优惠券"
      ) {
        this.$refs.addAndEdit.validate((valid) => {
          if (valid) {
            if (this.dialogTitle === "添加优惠券") {
              this.POST_sellerPromotionCouponsAdd();
            } else if (this.dialogTitle === "修改优惠券") {
              this.PUT_sellerPromotionCouponsEdit();
            }
            this.centerDialogVisible = false;
          } else {
            this.$message.error("请填写完整优惠券表单！");
            return false;
          }
        });
      } else if (this.dialogTitle === "停用优惠券") {
        this.PUT_sellerPromotionCouponsStatus(4);
        this.centerDialogVisible = false;
      } else if (this.dialogTitle === "删除优惠券") {
        this.DELETE_sellerPromotionCouponsDel();
        this.centerDialogVisible = false;
      } else if (this.dialogTitle === "定向发放优惠券") {
        this.$refs.directional.validate((valid) => {
          if (valid) {
            this.POST_sellerPromotionCouponsSend();
            this.centerDialogVisible = false;
          } else {
            this.$message.error("请选择要发放的用户！");
            return false;
          }
        });
      }
    },

    // 定向用户选中
    tableChoose(row) {
      var isNew = true;
      var index = -1;
      this.directionalUserList.forEach((item, itemIndex) => {
        if (item.member_id === row.member_id) {
          isNew = false;
          index = itemIndex;
        }
      });
      if (isNew) {
        this.directionalUserList.push(row);
      } else {
        this.directionalUserList.splice(index, 1);
      }
      this.directionalUserParams.directionalUser = "";
      this.directionalUserParams.ids = "";
      this.directionalUserList.forEach((item, index) => {
        if (index === 0) {
          this.directionalUserParams.directionalUser += item.mobile;
          this.directionalUserParams.ids += item.member_id;
        } else {
          this.directionalUserParams.directionalUser += "," + item.mobile;
          this.directionalUserParams.ids += "," + item.member_id;
        }
      });
    },

    // 导出优惠券明细
    downLoad() {
      var excelData = [];
      console.log(this.detailsTableData)
      this.detailsTableData.data.forEach((item) => {
        var excelDataRow = [];
        excelDataRow.push(item.uname);
        excelDataRow.push(item.mobile);
        excelDataRow.push(this.MixinUnixToDate("", "", item.create_date));
        excelDataRow.push(item.used_status === 1 ? '未使用' : item.used_status === 2 ? '已使用' : '已过期');
        excelDataRow.push(item.goods_name);
        excelDataRow.push(item.order_sn);
        excelDataRow.push(item.order_price);
        excelData.push(excelDataRow);
      });

      Export2Excel.export_json_to_excel(
        [
          "客户名称",
          "客户手机号",
          "优惠券发放时间",
          "是否使用优惠券",
          "活动名称",
          "订单编号",
          "活动价格",
        ],
        excelData,
        "优惠券明细"
      );
    },

    // 优惠券选中列表行
    couponsTableChoose(index) {
      this.tableData.data.forEach((item) => {
        item.checked = false;
      });
      var chooseRowDirectional = this.tableData.data[index];
      chooseRowDirectional.checked = true;
      this.tableData.data.splice(index, 1, chooseRowDirectional);
      this.chooseRowDirectional = chooseRowDirectional;
      this.directionalUserParams.couponId = chooseRowDirectional.coupon_id;
      console.log(this.chooseRowDirectional);
    },
  },
};
</script>

<style type="text/scss" lang="scss" scoped>
/deep/ .el-table td:not(.is-left) {
  text-align: center;
}

/deep/ .directionalTable {
  .tips-info {
    padding: 0;
    margin: 0;
  }

  .toolbar {
    padding: 0;
    margin: 0;
  }

  .pagination {
    border-radius: 0;
  }
}

/deep/ .detailsTable {
  .toolbar > div {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .tips-info {
    padding: 0;
    margin: 0;
  }
}

/*编辑基本信息*/
.el-form {
  padding-bottom: 80px;

  .el-form-item {
    width: 100%;
    text-align: left;
  }
}

.TitleBox {
  display: flex;
  flex-wrap: wrap;

  .conditions {
    display: flex;
    align-items: center;

    div {
      font-size: 15px;
      font-weight: 500;
      color: rgba(68, 68, 68, 1);
    }

    margin-bottom: 20px;
    margin-right: 20px;

    .choose-machine {
      width: 60%;
    }
  }
}

.buttonBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dialogContent {
  font-size: 16px;
  font-weight: 500;
  color: rgba(102, 102, 102, 1);
  text-align: center;
}

.searchBox {
  display: flex;
  align-items: center;
  margin: 0 0 10px;
}
</style>
