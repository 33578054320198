/**
 *  优惠券相关API
 */

import request from '@/utils/request'

/**
 * 查询优惠券列表
 * @param params
 */
export function sellerPromotionCoupons(params) {
  return request({
    url: '/seller/promotion/coupons',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 添加优惠券
 * @param params
 */
export function sellerPromotionCouponsAdd(params) {
  return request({
    url: '/seller/promotion/coupons',
    method: 'post',
    loading: false,
    params
  })
}

/**
 * 删除优惠券
 * @param id
 */
export function sellerPromotionCouponsDel(id) {
  return request({
    url: `/seller/promotion/coupons/${id}`,
    method: 'delete',
    loading: false
  })
}

/**
 * 优惠券停用启用
 * @param coupon_id status
 */
export function sellerPromotionCouponsStatus(coupon_id, status) {
  return request({
    url: `/seller/promotion/coupons/status/${coupon_id}`,
    method: 'put',
    loading: false,
    params: {
      "status": status
    }
  })
}

/**
 * 修改优惠券
 * @param params
 */
export function sellerPromotionCouponsEdit(params) {
  return request({
    url: '/seller/promotion/coupons/edit',
    method: 'put',
    loading: false,
    params
  })
}

/**
 * 查询发放用户
 * @param params
 */
export function sellerPromotionCouponsMember(params) {
  return request({
    url: '/seller/promotion/coupons/member',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 查询发放用户
 * @param ids,couponId
 */
export function sellerPromotionCouponsSend(ids, couponId) {
  return request({
    url: `/seller/promotion/coupons/${ids}_${couponId}`,
    method: 'post',
    loading: false
  })
}

/**
 * 查询优惠券明细
 * @param params
 */
export function sellerPromotionCouponsDetails(params) {
  return request({
    url: '/seller/promotion/coupons/member-list',
    method: 'get',
    loading: false,
    params
  })
}
